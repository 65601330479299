<template>
  <div class="content-box">
    <div class="title-img">
      <img src="@/assets/registeredAgent/icon.png" alt="" />
    </div>
    <div class="title-text">立即注册</div>
    <div class="from-box">
      <!-- <div class="item-box">
        <span class="label codeNum">推荐码：</span> <span class="label">{{ from.recommendCode }}</span>
      </div> -->
      <div class="item-box">
        <van-field :left-icon="comcode" v-model="from.recommendCode" readonly placeholder="推荐码" />
        <van-divider :hairline="false" class="divider-style" />
      </div>
      <div class="item-box">
        <van-field :left-icon="phone" v-model="from.phone" type="digit" maxlength="11" placeholder="请输入登录手机号" />
        <van-divider :hairline="false" class="divider-style" />
      </div>
      <div class="item-box code">
        <van-field :left-icon="code" v-model.trim="from.smsCode" maxlength="4" placeholder="请输入验证码">
          <template #button>
            <van-button
              class="code-btn"
              size="small"
              @click="getVerifyCodeEvent"
              :loading="loadingCaptcha"
              loading-type="spinner"
              type="primary"
              >{{ codeBtnText }}</van-button
            >
          </template>
        </van-field>
        <van-divider :hairline="false" class="divider-style" />
      </div>
      <div class="item-box">
        <van-field
          :left-icon="pwd"
          v-model.trim="from.password"
          :type="eyeShow ? 'text' : 'password'"
          clearable
          :right-icon="eyeShow ? eye2 : eye"
          @click-right-icon="rightIcon"
          placeholder="请设置登录密码"
        />
        <van-divider :hairline="false" class="divider-style" />
      </div>
      <div class="submit-btn" @click="submit">
        <van-loading v-if="loadingBtn" type="spinner" size="20px" class="btn-loading">立即注册</van-loading>
        <span v-else>立即注册</span>
      </div>
    </div>
    <div class="checkbox-style">
      <van-checkbox v-model="checkboxValue" checked-color="#FF4741" icon-size="17px">请阅读并勾选</van-checkbox
      ><span class="label" @click="webView(1)">《收付通合伙人合作协议》</span> 与<span class="label" @click="webView(3)"
        >《合伙人隐私政策》</span
      >
    </div>
    <van-dialog
      v-model="captchaShow"
      title="图形验证码"
      show-cancel-button
      :before-close="captchaConfirm"
      @open="captchaOpen"
    >
      <div class="code-box">
        <div class="code-img" @click="captchaOpen">
          <img v-if="imgUrl && !captchaError" :src="imgUrl" alt="" />
          <div v-else class="replay-box"><van-icon name="replay" />&nbsp;点击重试</div>
        </div>
        <div class="code-input">
          <van-field v-model="captcha" maxlength="6" placeholder="请输入图形验证码" />
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { agentSms, agentAdd, getCaptcha } from "@/api/registeredAgent";
import { Toast } from "vant";
import { shareConfig } from "@/utils/share.js";

export default {
  data() {
    return {
      eye: require("../../assets/registeredAgent/eye.png"),
      eye2: require("../../assets/registeredAgent/eye2.png"),
      from: {
        recommendCode: this.$route.query.recommendCode,
        // recommendCode: "10003106",
        phone: "",
        smsCode: "",
        password: "",
      },
      phone: require("../../assets/registeredAgent/phone.png"),
      code: require("../../assets/registeredAgent/code.png"),
      pwd: require("../../assets/registeredAgent/pwd.png"),
      comcode: require("../../assets/registeredAgent/comcode.png"),
      checkboxValue: false,
      codeBtnText: "获取验证码",
      codeBtnDisable: true,
      quickLink: "",
      title: "",
      isCaptcha: 0,
      captchaShow: false,
      imgUrl: "",
      captcha: "",
      captchaKey: "",
      captchaError: false,
      eyeShow: false,
      loadingBtn: false,
      loadingCaptcha: false,
      title: "<收付通展业版>快速注册",
      desc: "收付通展业助手、功能强大、操作便捷、数据清晰，立即注册开始使用吧！",
      imgUrl: "https://oss.zfshoufutong.com/image/98/44/8e/56/2b562479a8134743ab1f33ed79fe58d1.png", //展业logo
    };
  },
  created() {
    // this.getProtocol();
  },
  mounted() {
    shareConfig(this.title, this.desc, this.imgUrl);
  },
  methods: {
    rightIcon() {
      this.eyeShow = !this.eyeShow;
    },
    // 获取验证码效验
    getVerifyCodeEvent() {
      if (this.loadingCaptcha) {
        return;
      }
      if (!this.codeBtnDisable) {
        return;
      }
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;

      if (!this.from.phone) {
        Toast({
          message: "手机号码不能为空",
          icon: "cross",
        });
        return false;
      }
      if (!strTemp.test(this.from.phone)) {
        Toast({
          message: "手机号码格式不正确",
          icon: "cross",
          duration: 2 * 1000,
        });
        return false;
      }
      if (this.isCaptcha === 1) {
        this.captchaShow = true;
      } else {
        let params = {
          phone: this.from.phone,
          type: 0,
        };
        this.sendRequestCode(params);
      }
    },
    // 打开弹窗调取图形验证码接口
    captchaOpen() {
      getCaptcha()
        .then((res) => {
          this.captchaError = false;
          this.imgUrl = res.captcha;
          this.captchaKey = res.captchaKey;
        })
        .catch((err) => {
          this.captchaError = true;
          this.imgUrl = "";
          this.captchaKey = "";
        });
    },
    // 弹窗获取短息验证码
    captchaConfirm(action, done) {
      if (action === "confirm") {
        if (this.captcha == "") {
          Toast({
            message: "请输入图形验证码",
            duration: 2 * 1000,
            icon: "cross",
          });
          done(false);
          return;
        }
        let params = {
          phone: this.from.phone,
          captcha: this.captcha,
          captchaKey: this.captchaKey,
        };
        this.sendRequestCode(params);
        done();
        this.captcha = "";
      } else {
        done();
        this.captcha = "";
      }
    },
    // 获取验证码接口
    sendRequestCode(data) {
      this.loadingCaptcha = true;
      agentSms(data)
        .then((res) => {
          this.loadingCaptcha = false;
          this.isCaptcha = res;
          let time = 60,
            i = 0;
          this.codeBtnText = --time + "s后重新获取";
          let timer = setInterval(() => {
            this.codeBtnText = --time + "s后重新获取";
            i++;
            if (i == 1) {
              this.codeBtnDisable = false;
            }
          }, 1000);
          setTimeout(() => {
            this.codeBtnText = "获取验证码";
            this.codeBtnDisable = true;
            clearInterval(timer);
          }, time * 1000);
        })
        .catch((err) => {
          this.loadingCaptcha = false;
          if (err.code === 5001) {
            this.isCaptcha = 1;
            this.captchaShow = true;
          } else {
            Toast({
              message: err.message,
              icon: "cross",
            });
          }
        });
    },
    // 立即注册
    submit() {
      if (this.loadingBtn) {
        return;
      }
      if (!this.checkboxValue) {
        Toast({
          message: "请先阅读并勾选协议",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      if (this.from.phone === "") {
        Toast({
          message: "请填写登录手机号",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      let strTemp = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      if (!strTemp.test(this.from.phone)) {
        Toast({
          message: "手机号码格式不正确",
          icon: "cross",
          duration: 2 * 1000,
        });
        return false;
      }
      if (this.from.smsCode === "") {
        Toast({
          message: "请输入动态验证码",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      if (this.from.password === "") {
        Toast({
          message: "请输入密码",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      if (this.from.password.length < 6 || this.from.password.length > 15) {
        Toast({
          message: "请输入6-15位密码",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }
      let pwdTest = /^[0-9a-zA-Z]*$/g;
      if (!pwdTest.test(this.from.password)) {
        Toast({
          message: "请输入数字、字母、字母+数字组合的密码",
          duration: 2 * 1000,
          icon: "cross",
        });
        return;
      }

      this.loadingBtn = true;
      this.from.account = this.from.phone;
      let params = JSON.parse(JSON.stringify(this.from));
      // params.password = sha1(params.password);
      agentAdd(params)
        .then((res) => {
          Toast("注册成功");
          this.loadingBtn = false;
          this.$router.push("agentDownload");
        })
        .catch((err) => {
          this.loadingBtn = false;

          Toast({
            message: err.message,
            icon: "cross",
          });
        });
    },
    // 查看协议
    goAgreement() {
      this.$router.push({
        path: "/agreement",
        // query: {
        //   url: this.quickLink,
        //   title: this.title,
        // },
      });
    },
    //查看隐私政策
    goPrivacy() {
      this.$router.push({
        path: "/privacy",
      });
    },
    webView(type) {
      if (type) {
        if (type === 1) {
          window.location.href = "https://www.zfshoufutong.com/protocol/agencyCooperationAgreement.html";
        } else if (type === 2) {
          window.location.href = "https://www.zfshoufutong.com/protocol/agentManagementSpecification.html";
        } else if (type === 3) {
          window.location.href = "https://www.zfshoufutong.com/protocol/agentPrivacyAgreement.html";
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .van-button--primary {
  background-color: #ff4704;
  border: 1px solid #fff3f2;
  color: #ffffff;
  border-radius: 100px;
}
/deep/ .van-field__label {
  width: 100px;
}
// /deep/ .code .van-field__label {
//   line-height: 70px;
// }
/deep/ .van-field__left-icon {
  margin-right: 52px;
}
/deep/ .van-field__left-icon::after {
  content: "";
  width: 1px;
  height: 43px;
  position: absolute;
  top: 20px;
  // margin: auto 0px;
  left: 70px;
  background: #c9cdd4;
}
/deep/ .van-field__left-icon .van-icon {
  font-size: 50px;
}
.content-box {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: linear-gradient(180deg, #ffede7 0%, #ffffff 27%, #ffffff 100%);
  .title-img {
    margin: 80px auto 0px;
    width: 150px;
    height: 150px;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .title-text {
    padding-left: 51px;
    font-size: 40px;
    height: 48px;
    line-height: 48px;
    font-weight: bold;
    margin-top: 101px;
    color: #1d2129;
  }

  .from-box {
    padding: 51px 49px;
    box-sizing: border-box;
    // width: 698px;
    height: 760px;
    // margin: 63px 0px 136px 0px;
    // background: #ffffff;
    overflow: hidden;
    .item-box {
      margin-bottom: 34px;
      .label {
        font-size: 30px;
        color: #333;
        font-weight: bold;
      }
      .codeNum {
        font-size: 32px;
        font-weight: bold;
      }
      .divider-style {
        margin: 0;
      }
      .code-btn {
        font-size: 26px;
        position: absolute;
        right: 0px;
        top: -10px;
      }
    }
    .submit-btn {
      margin: 136px auto 0;
      width: 638px;
      height: 105px;
      line-height: 105px;
      text-align: center;
      background-color: #ff4741;
      border-radius: 20px;
      font-size: 32px;
      font-weight: 500;
      color: #ffffff;
      .btn-loading {
        color: #ffffff;
        .van-loading__text {
          color: #ffffff;
          font-size: 32px;
        }
      }
    }
  }
  .checkbox-style {
    margin: 55px 0 0;
    font-size: 24px;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .label {
      font-size: 24px;
      color: #ff4741;
    }
  }
}
.code-box {
  margin: 20px 0 40px;
  display: flex;
  justify-content: space-between;
  .code-img {
    width: 238px;
    height: 86px;
    border: 1px solid #e8e8e8;
    .replay-box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: #ff6b03;
    }
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .code-input {
    width: 276px;
    padding-left: 20px;
    border: 1px solid #e8e8e8;
  }
}
</style>
